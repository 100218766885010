import { Dialog, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'

import useStyles from './WelcomeDialog.styles'
import BaseButton from '../BaseButton'
import useMatomoLinkTracking, { MotomoLinkTrackingPosition } from '../../hooks/useMatomoLinkTracking'

const WELCOME_DIALOG_STORAGE_KEY = '0f3d4627-c119-4c46-9d4b-78661da2ed9c'

const WelcomeDialog = () => {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const [trackedLinkClick] = useMatomoLinkTracking()

  useEffect(() => {
    const wasShownBefore = localStorage.getItem(WELCOME_DIALOG_STORAGE_KEY)

    if (wasShownBefore !== '1') {
      setOpen(true)
    }
  }, [])

  const handleClose =
    ({ trackEvent }: { trackEvent?: boolean } = {}) =>
    () => {
      if (trackEvent) {
        trackedLinkClick({
          label: 'Background click close',
          href: '/close',
          position: MotomoLinkTrackingPosition.POPUP,
          isCta: true,
        })(true)
      }

      localStorage.setItem(WELCOME_DIALOG_STORAGE_KEY, '1')

      setOpen(false)
    }

  return (
    <Dialog
      classes={{ paper: classes.paper, scrollPaper: classes.dialogScrollPaper }}
      onClose={handleClose({ trackEvent: true })}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Typography className={classes.title}>
        Willkommen bei
        <br />
        Merz Aesthetics®
      </Typography>
      <Typography className={classes.copy}>
        Um die für Sie relevanten Inhalte zu sehen, wählen Sie bitte{' '}
        <span className={classes.copyBreak}>Ihren Bereich aus:</span>
      </Typography>

      <div className={classes.actions}>
        <BaseButton
          classNames={classes.action}
          type="secondary"
          fullWidth
          label="Non-Professional"
          onClick={trackedLinkClick({
            label: 'Non-Professional',
            href: '/close',
            position: MotomoLinkTrackingPosition.POPUP,
            clickHandler: handleClose(),
            isCta: true,
          })}
        />

        <BaseButton
          classNames={classes.action}
          type="secondary"
          fullWidth
          label="Arzt:Ärztin"
          target="_blank"
          href="https://mmp.merz-aesthetics.de"
          onClick={trackedLinkClick({
            label: 'Arzt:Ärztin',
            href: 'https://mmp.merz-aesthetics.de',
            position: MotomoLinkTrackingPosition.POPUP,
            isCta: true,
          })}
        />
      </div>
    </Dialog>
  )
}

export default WelcomeDialog
